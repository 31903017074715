@import "../../variables.scss";
@import "../../mixins.scss";

.deviceControls{
    grid-column: 2/3;
    min-height: 600px;
    background-color: $backgroundColor;

    @include responsive-max($size-sm){
        background-color: $backgroundColor;
    }
}