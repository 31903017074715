@import "../../variables.scss";
@import "../../mixins.scss";

.voiceControlDeviceCard {
    display: grid;
    grid-template-columns: 100px 6fr 100px;
    grid-template-rows: 1fr;
    min-height: 70px;
    padding: 5px 10px;

    &:nth-child(2n+1) {
        background-color: $backgroundColorAltRows;
    }

    &:nth-child(2n) {
        background-color: $backgroundColor;
    }

    @include responsive-max($size-xl) {
        grid-template-columns: 80px 6fr 80px;
    }

    &:hover {
        cursor: pointer;
    }

    .deviceImage {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 60px;
        padding: 5px;

        img {
            height: 95%;
            width: 100%;
        }
    }

    .deviceInfo {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: start;

        @include responsive-max($size-sm) {
            grid-column: 1/2;
        }

        .deviceModel {
            color: $linkColor;
            grid-row: 1/2;
            align-self: end;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .deviceFriendlyName {
            grid-row: 2/3;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .deviceStatus {
        align-items: center;
        display: flex;
        justify-content: center;

            svg{
                height: 100%;
                width: 35%;
            }

        @include responsive-max($size-sm) {
            grid-column: 2/3;

            svg{
                transform: rotate(-90deg);
            }
        }
    }
}

.voiceControlDeviceCard.selected {
    @include responsive-min($size-sm) {
        background-color: $actionColor;
        color: $lightTextColor;

        .deviceModel {
            color: $lightTextColor;
        }

        .deviceStatus {
            color: $lightTextColor;
        }
    }
}