@import "../../variables.scss";
@import "../../mixins.scss";

@mixin inline-arrow-svg($hexColor) {
  $usedColor: "%23#{str-slice($hexColor, 2, -1)}";

  // Inlined using https://yoksel.github.io/url-encoder/ ("Ready for CSS" method)
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.131' height='8.692' viewBox='0 0 16.131 8.692'%3E%3Cg id='Group_9' data-name='Group 9' transform='translate(-1206.29 -738.977)'%3E%3Cline id='Line_7' data-name='Line 7' x2='7.584' y2='7.586' transform='translate(1206.791 739.479)' fill='none' stroke='#{$usedColor}' stroke-miterlimit='10' stroke-width='1.417'/%3E%3Cline id='Line_8' data-name='Line 8' x1='7.647' y2='7.648' transform='translate(1214.273 739.519)' fill='none' stroke='#{$usedColor}' stroke-miterlimit='10' stroke-width='1.417'/%3E%3C/g%3E%3C/svg%3E ");
}

.selectWrapper {
  position: relative;

  &:after {
    position: absolute;
    @include inline-arrow-svg(#{$linkColor});
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    pointer-events: none;
  }
}

.marginBottom {
  margin-bottom: 20px;
}

.select {
  width: 100%;
  min-height: 47px;
  line-height: 17px !important;
  color: $textColor;
  border-radius: 10px;
  min-width: 100px;
  background-color: $formFieldBgColor;
  outline: none;
  // reset
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.label {
  margin-bottom: 5px;
  display: block;
}

.typeIcon {
  justify-self: center;
}

.control {
  min-height: unset;
  line-height: 17px;
}

.singleValue {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 60px 1fr;
  align-items: center;

  .selectedText {
    padding: 15px 0px
  }

  img {
    height: 28px;
    justify-self: center;
  }
}

.menuList {
  background-color: $backgroundColor;
  border: 2px solid $backgroundLoggedColor;
  border-radius: 10px;
  max-height: unset;

  .option {
    padding: 15px 0px;
    display: grid;
    height: fit-content;
    grid-template-columns: 60px 1fr;
    justify-items: left;
    align-items: center;

    &:hover {
      background-color: $backgroundLoggedColor;
    }
  }
}

.placeHolder{
  padding: 15px 18px;
}