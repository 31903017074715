@import "../../variables.scss";
@import "../../mixins.scss";
@import "../../shared.module.scss";

.deviceCard {
  @include listViewDeviceCardBackgroundColor;
  color: $textColor;

  @include responsive-min($size-md) {
    padding: 14px 41px;
  }

  @include responsive-max($size-md) {
    padding: 11px 19px;
  }
}

.deviceCardWrapper {
  display: flex;
  align-items: center;
  min-height: 35px;

  @include responsive-max($size-md) {
    justify-content: space-between;
  }
}

.deviceLink {
  text-decoration: none;
}

.deviceImage {
  display: flex;
  img {
    width: 50px;
    height: 35px;
  }

  @include responsive-max($size-md) {
    display: none;
  }
}

.deviceInfo {
  .model,
  .deviceName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .deviceModel {
    margin-left: 21px;
    width: 250px;

    @include responsive-max($size-lg) {
      width: 200px;
    }

    @include responsive-max($size-md) {
      margin-left: 0;
      color: $linkColor;
      margin-bottom: 7px;
    }
  }

  .deviceName {
    margin-left: 10px;
    width: 100px;

    @include responsive-max($size-md) {
      color: $textColor;
      margin-left: 0;
      width: 150px;
      flex-grow: 1;
    }

    @include responsive-min($size-md) {
      flex-grow: 1;
    }
  }

  @include responsive-min($size-md) {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  @include responsive-max($size-md) {
    align-self: flex-start;
  }
}

.deviceBtnGroup {
  display: flex;
  align-items: center;
  margin-left: 10px;
  min-width: 60px;
  color: $actionColor;

  .devicePlayIcon {
    margin-left: 23px;
  }

  .deviceMicroIcon{
    margin-left: 23px;
  }

  .devicePlayIcon,
  .deviceSettingsIcon,
  .deviceMicroIcon {
    cursor: pointer;
  }
}

.deviceIconLink {
  display: inline-flex;
  margin-bottom: 0;

  // Override default link colors
  color: $actionColor;
  &:hover, &:active {
    color: inherit;
  }
}
