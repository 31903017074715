@import "../../variables.scss";
@import "../../mixins.scss";

.DeviceStatusMessage{
    align-items: center;
    background-color: $backgroundColor;
    display: grid;
    grid-template-rows: 1fr 1fr;
    font-size: 18px;
    padding: 5%;

    @include responsive-max($size-sm){
        padding-top: 80px;
    }
}

.statusIcon{
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 90px;

    svg{
        height: 90px;
        max-width: 90px;
        width: 30%;
    }

    .spinnerMessage{
        height: 32px;
        width: 32px;
    }
}

.dangerButton{
    min-width: 0px;
}

.textMessage{
    text-align: center;
}

.incompatible{
    color: #e40d0d;
}

.offline{
    color: $actionColor;
}

.checking{
    display: flex;
    align-items: flex-end;
}