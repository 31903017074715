@import "../../variables.scss";
@import "../../mixins.scss";

.controlList{

    @include responsive-max($size-sm){
        background-color: $backgroundColor;
    }

    &>div:nth-child(2n+1){
        background-color: $backgroundColorAltRows;
            
        @include responsive-max($size-sm){
            background-color: $backgroundColor;
        }
    }

    &>div:nth-child(2n){         
        @include responsive-max($size-sm){
            background-color: $backgroundColorAltRows;
        }
    }
}

.addNewControlLink{
    min-height: 60px;
}

.addNewControlLink{
    align-items: center;
    color: $actionColor;
    display: grid;
    grid-template-columns: 100px 6fr 100px;
    grid-template-rows: 1fr;
    height: 70px;
    padding: 10px;

    @include responsive-max($size-xl){
        grid-template-columns:  80px 12fr 80px
    }

    @include responsive-max($size-sm){
        grid-template-columns: 1fr 5fr;
        font-size: inherit;
        padding: 15px 0px;
    }
}

.addNewControlIcon{
    align-content: center;
    display: flex;
    grid-column: 1/2;
    justify-content: center;

    svg{
        height: 100%;
        width: 15px;
    }
}

.skeleton{
    height: 70px;
    padding: 10px;
    margin: 1px 0px
}