@import "../../variables.scss";
@import "../../mixins.scss";

.controlCard{
    align-items: center;
    display: grid;
    grid-template-columns: 100px 12fr 100px;
    grid-template-rows: 1fr;
    min-height: 70px;
    padding: 5px 10px;

    @include responsive-max($size-xl){
        grid-template-columns: 70px 6fr 70px;
    }

    @include responsive-max($size-sm){
        grid-template-columns: 1fr 5fr 1fr;
        padding: 10px;
    }
}

.controlIcon{
   grid-column: 1/2;
   justify-self: center;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   img{
    height: 100%;
    width: 28%;

    @include responsive-max($size-sm){
        width: 45%;
    }
}
}

.controlName{
    grid-column: 2/3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.editControlIcon{
    display: flex;
    grid-column: 3/4;
    justify-content: center;

    @include responsive-max($size-sm){
        padding-right: 20px;
    }
    
    a{
        align-content: center;
        color: $actionColor;
        display: flex;
    }
}