@import "../../variables.scss";

@keyframes spinner {
    to {transform: rotate(360deg);}
  }
  

.spinner {
    box-sizing: border-box;
    position: absolute;
    width: 25.6px;
    height: 25.6px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: inherit;
    animation: spinner .6s linear infinite;
  }
  