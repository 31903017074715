@import "../variables.scss";
@import "../mixins.scss";


.voiceControlPage {

    @include responsive-min($size-sm) {
        .headerContainer {
            max-width: none;
            padding: 0px;
        }
    }

    @include responsive-max($size-sm) {
        padding: 0px;
    }
}

.voiceControlMenu {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    font-size: 14px;
    gap: 20px;

    @include responsive-max($size-xl) {
        gap: 10px;
    }

    @include responsive-max($size-sm) {
        flex-direction: column;
    }
}

.voiceControlContainer {
    background-color: $backgroundColor;
    border-radius: 7.5px;
    margin-bottom: 30px;
    min-height: 600px;
    padding: 20px;
    padding-top: 35px;

    @include responsive-max($size-sm) {
        background-color: $backgroundLoggedColor;
        min-height: 60px;
        padding: 0px;
    }
}