@import "../../variables.scss";
@import "../../mixins.scss";

.voiceControlDeviceCard {
    display: grid;
    grid-template-rows: 1fr;
    height: 70px;
    grid-template-columns: 6fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 0px 25px;

    &:nth-child(2n+1) {
        background-color: $backgroundColor;
    }

    &:nth-child(2n) {
        background-color: $backgroundColorAltRows;
    }

    .deviceInfo {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: start;
        grid-column: 1/2;

        .deviceModel {
            color: $linkColor;
            grid-row: 1/2;
            align-self: end;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .deviceFriendlyName {
            grid-row: 2/3;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .deviceStatus{
        align-items: center;
        display: flex;
        grid-column: 2/3;
        justify-content: center;

            svg{
                height: 30px;
                width: 100%;
            }
    }

    .arrow{
        display: flex;
        grid-column: 3/4;
        justify-content: center;
        align-items: center;

        svg{
            height: 22.5%;
            width: 100%;
            transform: rotate(-90deg)
        }
    }
}

.voiceControlDeviceCard.selected {
    @include responsive-min($size-sm) {
        background-color: $actionColor;
        color: $lightTextColor;

        .deviceModel {
            color: $lightTextColor;
        }

        .deviceStatus {
            color: $lightTextColor;
        }
    }
}