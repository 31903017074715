@import "../../variables.scss";
@import "../../mixins.scss";

.deviceCard {
  padding: 20px;
  background-color: $backgroundColor;
  border-radius: 7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150px 110px;
}

.deviceCardWrapper {
  height: 100%;
  position: relative;
}

.deviceLink {
  text-decoration: none;
}

.deviceModel {
  line-height: 1.6em;
  color: $linkColor;
  margin-bottom: 2px;
}

.deviceName {
  color: $textColor;
}

.deviceModel,
.deviceName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 290px;
}

@include responsive-between($size-sm, $size-lg) {
  .deviceModel,
  .deviceName {
    width: 130px;
  }
}

@include responsive-max($size-sm) {
  .deviceModel,
  .deviceName {
    width: 240px;
  }
}

.deviceBtnGroup {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: $actionColor;

  .devicePlayIcon {
    margin-left: 23px;
  }

  .deviceMicroIcon{
    margin-left: 23px;
  }

  .devicePlayIcon,
  .deviceSettingsIcon,
  .deviceMicroIcon{
    cursor: pointer;
  }
}

.deviceIconLink, .deviceMicroIcon {
  display: inline-flex;
  margin-bottom: 0;

  // Override default link colors
  color: $actionColor;
  &:hover, &:active {
    color: inherit;
  }
}
