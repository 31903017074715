@import "../variables.scss";
@import "../mixins.scss";

.avatar {
  margin-left: 15px;
  //height: $avatarSize;  // set in JS
  //width: $avatarSize;   // set in JS
  border-radius: 100%;
}

.userDropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $headerTextColor;
}

.userDropdownWrapper {
  display: flex;
  align-items: baseline;
  overflow: hidden;
}

.arrow,
.avatar,
.userName {
  cursor: pointer;
}

.arrowOpen {
  transform: rotate(180deg);
}

.arrow {
  margin-left: 10px;
  flex-shrink: 0;
}

.optionItem {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  cursor: pointer;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: $linkColor;

  &:nth-child(-n + 3) {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $popOverColor;
  }

  .optionItemName {
    margin: 0;
    justify-self: start;
  }
}

.icon {
  margin-left: 11px;
  justify-self: right;
}

.userName {
  display: inline-block;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 250px;
  padding-left: 10px;
}

@include responsive-between($size-xs, $size-xsm) {
  .userName {
    display: none;
  }
}
